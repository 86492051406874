














import { Vue, Component, Prop } from 'vue-property-decorator';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

import { Participant } from '@/models/study/models';
import { globalStore } from '@/store/modules/global';
import { studyRouteName } from '../app';

@Component({
  mixins: [BeforeLeaveGuard],
})
export default class ParticipantForm extends Vue {
  @Prop({ required: true }) id: string;

  Participant = Participant;

  async beforeSaveHook(participant: Participant): Promise<void> {
    if (this.$props.id === '0') {
      participant.application = globalStore.selection.application?.id;
      // get initial state
      // try/catch and loading handled by baseformv2
      const setting = globalStore.clientAppSetting('config');
      participant.state = setting?.value?.participant_default_state || '';
    }
  }

  onCreated(participant: Participant): void {
    // now that the participant has been created, we want to show the details
    this.$routerHandler.push({
      name: studyRouteName('clinic.patient-detail'),
      params: {
        id: participant.id,
      },
    });
  }
}
